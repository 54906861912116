import React from 'react'

import Layout from '../components/layout'
import HomeOne from '../components/home/HomeOne'
import HomeTwo from '../components/home/HomeTwo'
import HomeThree from '../components/home/HomeThree'
import HomeFour from '../components/home/HomeFour'

class Home extends React.Component {
  render() {
    return (
      <Layout lang="pt" title="Soluções Tecnológicas Impactantes" meta={{description: 'A sua equipa de desenvolvimento de software ágil.'}} rootProps={{ className: 'page home' }}>
        <HomeOne lang='pt' />
        <HomeTwo lang='pt' />
        <HomeThree lang='pt' />
        <HomeFour lang='pt' />
      </Layout>
    )
  }
}

export default Home
